import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import AccountGate from "~/components/account/AccountGate"
import { useApplicationState } from "~/hooks/useApplicationState"
import { usePrinticularApi } from "~/hooks/usePrinticularApi"
import { ReactComponent as EmailSent } from "~/images/EmailSent.svg"
import ErrorAlert from "../../components/ErrorAlert"

type FormData = {
  emailAddress: string
}

const Register = () => {
  const api = usePrinticularApi()
  const { baseUrl } = useApplicationState()

  const [error, setError] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [isFormValidated, setIsFormValidated] = useState(false)
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data: FormData) => {
    setIsLoading(true)

    try {
      const { emailAddress } = data
      await api.register({
        emailAddress,
        siteUrl: baseUrl,
      })
      setIsFormValidated(true)
    } catch (error) {
      setError(error)
    }

    setIsLoading(false)
  }

  return (
    <AccountGate>
      <Box my={{ base: 0, md: 20 }} mx="auto" maxWidth="100%" width="2xl">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={6}>
            <Box>
              <Heading as="h1" size="lg" mb={3}>
                {t("pages.register.index.RegisterAccount")}
              </Heading>
              <Text m={0} fontSize="md">
                {t("pages.register.index.RegisterDescription")}
              </Text>
            </Box>

            {error && !isFormValidated && (
              <ErrorAlert data={error} displayErrorsList={false} />
            )}

            {!isFormValidated && (
              <>
                <FormControl isInvalid={!!errors.emailAddress}>
                  <Input
                    id="register-email-address"
                    type="email"
                    autoComplete="email"
                    placeholder={t("pages.register.index.Email")}
                    variant="filled"
                    {...register("emailAddress", {
                      required: t<string>("pages.register.index.EmailRequired"),
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: t("pages.register.index.EmailError"),
                      },
                    })}
                  />
                  {!!errors.emailAddress && (
                    <FormErrorMessage>
                      {errors.emailAddress.message}
                    </FormErrorMessage>
                  )}
                </FormControl>

                <Box>
                  <Button
                    id="register"
                    colorScheme="primary"
                    color="primary.text"
                    type="submit"
                    isLoading={isLoading}
                  >
                    {t("pages.register.index.Submit")}
                  </Button>
                </Box>
              </>
            )}

            {isFormValidated && <SuccessRegister />}
          </Stack>
        </form>
      </Box>
    </AccountGate>
  )
}

export default Register

const SuccessRegister = () => {
  const { t } = useTranslation()
  return (
    <Box>
      <Alert
        status="success"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        borderRadius={4}
        padding={[8, 10]}
      >
        <AlertIcon boxSize={10} mr={0} />
        <AlertTitle mx={0} mt={4} mb={2} fontSize="lg">
          {t("pages.register.index.Submitted")}
        </AlertTitle>
        <AlertDescription fontSize="sm">
          {t("pages.register.index.SubmittedInfo")}
        </AlertDescription>
      </Alert>

      <Box width="80%" mx="auto" mt={10}>
        <EmailSent />
      </Box>
    </Box>
  )
}
